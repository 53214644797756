import React from 'react'
import './App.css';
import LoadPage from "./AllPages";



function App() {
  return (
      <div>
     <LoadPage/>
      </div>
  );
}

export default App;

